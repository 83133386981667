<template>
    <div id="ZoomIndicator">
        <h3>Current Zoom {{state.zoom}}</h3>
        <h3>Click to select a {{this.selection}}</h3>
    </div>
</template>
<script>
import { reactive } from '@vue/composition-api'
export default {
    name: 'ZoomIndicator',
    props: {
        zoomLevel: {
            type: Number,
            required: true
        }
    },
    computed: {
        selection: function() {
            if(this.state.zoom >= 15) {
                return "Individual Place"
            }
            if(this.state.zoom >= 13) {
                // return "Suburb"
                return "Individual Place"
            }
            if(this.state.zoom >= 10) {
                return "City"
            }
            if(this.state.zoom >= 7) {
                return "Region"
            }
            return "Country"

        }
    },
    watch: {
        zoomLevel: function(newZoom){
            console.log("Zoom updated", newZoom)
            this.state.zoom = newZoom
        }
    },
    setup(props) {
        const state = reactive({
            zoom: props.zoomLevel,
        })
        return {
            state,
        }
    },
}
</script>
<style scoped>
#ZoomIndicator {
    background-color: white;
}
h3 {
    font-size: 12pt;
    padding: 0.5em;
    margin: 0;
}
p {
    text-align: left;
    padding: 0em 1em 0em 1em;
    margin: 0;
}
</style>