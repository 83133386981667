<template>
<!-- Displays the name and tags of a place -->
    <div id="PlaceInformation">
        <div class="information-item">
            <h2>{{state.placeName}}</h2>
        </div>
        <div class="information-item">
        </div>
        <div class="information-item">
            <ul>
                <li
                    :key="'tag' + index"
                    v-for="(m, index) in state.extraTags">{{state.propertyNames[index]}}: {{m}}</li>
            </ul>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/composition-api'
export default {
    name: 'PlaceInformation',
    props: {
        description: String,
        // TODO make this more type specific
        properties: Object,
    },
    watch: {
        properties: function (newProperties) {
            this.updatePlaceInformation(newProperties)
        }
    },
    mounted() {
        this.updatePlaceInformation(this.properties)
    },
    setup() {
        const state = reactive({
            placeName: String,
            extraTags: [],
            propertyNames: Array,
        })

        // Updates the displayed information based on properties given to it
        function updatePlaceInformation(properties) {
            if(properties) {
                if(properties['name']) {
                state.placeName = properties['name']
                }
                else {
                    state.placeName = "No formal name listed"
                }
            let tags = []
            let propNames = Object.getOwnPropertyNames(properties)
            propNames.forEach(element => {
                // We don't want to show the name id or object properties just the other osm tags
                if(element != 'name' && element != 'id' && element != '__ob__') {
                    tags.push(properties[element])
                    console.log(typeof(properties[element]))
                }
            });
            state.propertyNames = propNames
            state.extraTags = tags
            }
            else {
                state.placeName = "No formal name listed"
            }
        }
        
        return {
            state,
            updatePlaceInformation,
        }
    },
}
</script>
<style scoped>
    ul {
        list-style: none;
        padding: 1em 0em;
        margin: 0;
    }
    p, h3 {
        text-align: left;
    }
    p {
        padding-top: 1em;
    }
    li {
        text-align: left;
    }

    h2 {
        border-bottom: 2px solid var(--dark);
        margin: 0;
        padding: 0.5em 0em 0em 0em;
    }
</style>