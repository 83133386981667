<template>
<!-- Helper text and textboxes for renaming a place -->
<div id="UpdateInformation">
    <div class="information-view">
        <!-- Place Information displays the name and info -->
        <place-information :properties="currentElement"/>
    </div>
    <div class="information-update">
        <div class="information-item">
            <h3>Add a Name:</h3>
            <p class="helper">What do you call this place?</p>
            <div class="input-wrapper">
            <input v-model="state.placeName" style="width: 100%;" placeholder="New Name">
            </div>
        </div>
        <div class="information-item">
            <h3>Whats the Story?</h3>
            <p class="helper">
                Tell us about why you call this place that. 
                Maybe everyone in your family calls it that, so you do too. 
                Is it a piece of slang picked up by your friends?
                There is no wrong answer here!
            </p>
            <div class="input-wrapper">
            <textarea v-model="state.description" style="width: 100%;" placeholder="I call it this because..."></textarea>
            </div>
        </div>
        <div class="update-buttons">
            <b-button @click="saveToDatabase(geojson)" variant="dark">Add Name</b-button>
        </div>
    </div>
    <!-- Carousel of other names if the selected item is a pin -->
    <div v-if="this.details">
    <div class="custom-names">
        <h3> Other Names</h3>
        <carousel class="carousel" :details="this.details"/>
    </div>
    </div>
</div>
</template>

<script>
import {reactive} from '@vue/composition-api'
import Carousel from '../Carousel/Carousel'
import PlaceInformation from './PlaceInformation'
import graphql from '../../api/graphql'
import mapQuery from '../../api/mapQuery'
export default {
    name: 'UpdateInformation',
    components: {
        Carousel,
        PlaceInformation
    },
    props: {
        geojson: Object,
        marker: Object,
        customDetails: Array,
    },
    computed: {
        currentElement() {
            if(this.geojson) {
            return this.geojson.features[this.state.index].properties;
            }
            else {
                return null
            }
        },
        details() {
            if(this.customDetails && this.customDetails.length > 0) {
                return this.customDetails
            }
            else {
                return false
            }
        }
    },
    setup() {
        const state = reactive({
            placeName: "",
            description: "",
            slide: 0,
            selectedIndex: 0,
            index: 0,
        })
        function getOpId(geojson) {
            return geojson.features[state.selectedIndex].id
        }
        function getWayNodes(geojson) {
            let points = geojson.features[state.selectedIndex].geometry.coordinates[0]
            //TODO improve checks
            if(points.length < 1) {
                //TODO throw exception
                return null;
            }
            else {
                return points
            }
        }
        function saveToDatabase(geojson) {
            //TODO Check input
            let opId = getOpId(geojson)
            let wNodes = getWayNodes(geojson)
            let graphQuery = mapQuery.saveQuery()
            graphql.query(graphQuery, {
                placeInput: {
                    name: state.placeName,
                    description: state.description,
                },
                way: {
                    overpassId: opId,
                    points: wNodes,
                },
                pin: {
                    pId: this.marker.pId,
                    pointX: this.marker.pointX,
                    pointY: this.marker.pointY
                }
                }).then( () =>{
                makeToast("Update Success", "Your name and story has been added.", this)
                // TODO make sure pin ID is updated on success (just refresh?)
                state.placeName = ""
                state.description = ""
            }).catch(ex => {
                makeToast("Update Error", ex, this)
            })

            // Toasts for showing success/errors
            function makeToast(ttl, msg, context) {
                context.$bvToast.toast(msg, {
                    title: ttl,
                    autoHideDelay: 3000,
                    solid: true,
                })
            }
        }
            return {
            state,
            saveToDatabase,
            getWayNodes,
        }
    },
}
</script>

<style scoped> 
    #UpdateInformation {
        padding: 0em 1em 2em 1em;
        height: 100%;
        overflow-y: auto;
    }
    .information-update {
        text-align: left;
        padding: 0em 1em;
        border: 2px solid var(--dark);
        border-radius: 2em;
    }

    .carousel {
        border: 2px solid var(--dark);
        border-radius: 2em;
    }

    .custom-names h3 {
        text-align: left;
        padding: 1em 0em 0.5em 0em;
    }

    input, textarea {
        border: 1px solid var(--dark);
    }

    .input-wrapper {
        text-align: center;
    }

    .helper {
        margin: 0;
        font-size: 1rem;
        color: var(--gray);
        padding: 0em 0em .25em 0em;
    }

    .update-buttons {
        text-align: center;
        padding-bottom: 1em;
    }

    h3 {
        padding: 0.5em 0em 0em 0em;
        margin: 0;
    }


</style>
