<template>
    <div id="AddWay"> 
        <div class="control-wrapper" :style="this.display">
        <div v-if="state.changeBoundary">
            <div class="flex-wrapper">
                <div class="item-text">
            <p>Click on the map to add a new border</p>
                </div>
                <div class="item-button">
            <b-button @click="updateBoundary()">Update</b-button>
            <b-button @click="cancelBoundary()">Cancel</b-button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="flex-wrapper">
                <div class="item-text">
            <p>Not the border you want?</p>
                </div>
                <div class="item-button">
            <b-button @click="changeBoundary()" variant="dark">Change Boundary</b-button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
// ########## Component that handles UI for adding custom paths ##########
import {reactive} from '@vue/composition-api'
export default {
    props: {
        show: Boolean
    },
    computed: {
        display() {
            if(this.show) {
                return "display: block;"
            }
            else {
                return "display: none;"
            }
        }
    },
    setup() {
        const state = reactive({
            changeBoundary: false,
        })
        // Alert paretn of the state
        function changeBoundary() {
            this.$emit("change-boundary")
            state.changeBoundary = true;
        }
        function cancelBoundary() {
            this.$emit("cancel-boundary")
            state.changeBoundary = false;
        }
        function updateBoundary() {
            this.$emit("update-boundary")
            state.changeBoundary = false;
        }
        return {
            state,
            changeBoundary,
            cancelBoundary,
            updateBoundary,
        }
    }
}
</script>
<style scoped>
    #AddWay {
        background-color: white;
    }
    .control-wrapper {
        padding: 0.5em;
    }
    .flex-wrapper {
        display: flex;
    }
    .item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0em 0.5em;
    }
    p {
        margin: 0;
        font-size: 1.5em;
    }
</style>