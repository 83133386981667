<template>
<div id="mapAdd">
    <div class="info-wrapper">
        <div v-if="state.selection.ready" style="width: 100%; height: 100%">
            <update-information :customDetails="state.preload.details" :marker="state.selection.marker" :geojson="state.selection.geojson" />
        </div>
        <div v-else style="width: 100%; height: 100%">
            <user-prompt v-bind:view="false" />
        </div>
    </div>
    <div class="map-wrapper">
        <l-map @click="addToMap" ref="myMap" @ready="initialize()" style="height: 100%" :zoom="state.mapProperties.zoom" :center="state.mapProperties.center">
            <l-tile-layer :url="state.mapProperties.url" :attribution="state.mapProperties.attribution"></l-tile-layer>
            <l-control position="bottomleft">
                <add-way v-on:change-boundary="changeBoundary" v-on:cancel-boundary="cancelBoundary" v-on:update-boundary="updateBoundary" :show="state.selection.ready" />
            </l-control>
            <l-control position="topright">
                <zoom-indicator :zoomLevel="state.mapProperties.zoom" />
            </l-control>
            <l-marker :key="index" v-for="(m, index) in state.preload.markers" :lat-lng="[m.pointX, m.pointY]" @click="pinClick(m)"></l-marker>
            <l-polygon :lat-lngs="state.preload.polygons"></l-polygon>
            <div v-if="state.selection.marker.length != 0">
                <l-marker :lat-lng="[
              state.selection.marker.pointX,
              state.selection.marker.pointY,
            ]" :color="state.colors.marker"></l-marker>
            </div>
            <l-circle-marker :key="'circle' + index" v-for="(m, index) in state.selection.circleMarkers" :lat-lng="m" :radius="state.constants.circleRadius" :color="state.colors.circle" />
            <l-polygon :lat-lngs="state.polygon" :color="state.colors.polygon"></l-polygon>
            <l-polyline :lat-lngs="state.polyline" :color="state.colors.polyline"></l-polyline>
        </l-map>
    </div>
</div>
</template>

<script>
import {
    reactive
} from "@vue/composition-api";
import L from "leaflet";
import {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LPolyline,
    LControl,
    LCircleMarker,
} from "vue2-leaflet";
import AddWay from "./AddWay";
import UpdateInformation from "./UpdateInformation";
import UserPrompt from "./UserPrompt";
import mapQuery from "../../api/mapQuery";
import ZoomIndicator from "./ZoomIndicator.vue";
import mapfunctions from "./mapfunctions.js";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPolygon,
        LPolyline,
        LControl,
        LCircleMarker,
        UpdateInformation,
        UserPrompt,
        AddWay,
        ZoomIndicator,
    },
    setup() {
        const state = reactive({
            mapProperties: {
                url: mapfunctions.url,
                //TODO look to add overpass attribution
                attribution: mapfunctions.attribution,
                center: mapfunctions.center,
                zoom: mapfunctions.zoom,
            },
            map: null,
            preload: {
                markers: [],
                polygons: [],
                details: [],
                pId: String,
            },
            updatingBoundary: false,
            layerGroup: null,
            geojsonLayer: L.geoJSON(),
            osmtogeojson: require("osmtogeojson"),
            selection: {
                ready: false,
                geojson: Object,
                marker: [],
                name: String,
                extraTags: [],
                info: [],
                circleMarkers: [],
            },
            polygon: [],
            polyline: [],
            oldBoundary: [],
            constants: {
                circleRadius: 2,
            },
            colors: {
                polygon: "purple",
                polyline: "purple",
                circle: "black",
                marker: "red",
            },
        });

        // Runs when the map is ready to be loaded in
        function initialize() {
            state.map = this.$refs.myMap.mapObject;
            // Whenever the map finishes zooming, update the zoom var
            state.map.on("zoomend", function () {
                state.mapProperties.zoom = state.map.getZoom();
            });
            // layergroup for adding geoJson too easily
            state.layerGroup = new L.LayerGroup();
            state.layerGroup.addTo(state.map);
            // Load in all the pins
            loadPin();
        }

        // ########## LOADING AND CLICKING PINS ##########

        // Loads all the pins from the database onto the map
        function loadPin() {
            mapQuery
                .loadAllPins()
                .then((json) => {
                    state.preload.markers = json.data.pinData;
                })
                .catch((ex) => {
                    console.log("something went wrong", ex);
                });
        }

        // Loads the details window with the saved details of the pin
        function pinClick(point) {
            mapQuery
                .getPinInfoById(point.pId)
                .then((json) => {
                    state.preload.details = json.data.placeData;
                    state.preload.pId = point.pId;
                    // TODO check will this add multiple points in the same location
                    addPin(point);
                })
                .catch((ex) => {
                    console.log(ex);
                    // TODO should this be returning null
                    return null;
                });
        }

        // ########## FINDING DATA FROM CLICK ##########

        // Triggered when the map is clicked
        // Creates a point from the x and y coord and decides what to do next
        function addToMap(event) {
            const point = {
                pointX: event.latlng.lat,
                pointY: event.latlng.lng,
            };
            if (!state.updatingBoundary) {
                state.polygon = [];
                addPin(point);
            } else {
                addToBoundary(point);
            }
        }

        // Adds a pin to the map with data from either overpass or photon
        function addPin(point) {
            let options = {};
            // If the admin level isnt 0 we are searching for a large boundary
            let largeBoundary = (mapfunctions.getAdminLevel(state.mapProperties.zoom) != 0);   
            mapQuery.getOverpassDataByPoint(point, mapfunctions.getAdminLevel(state.mapProperties.zoom)).then((json) => {
                // If overpass found something
                if (json.elements.length > 0) {
                    if (largeBoundary) {
                        let members = json.elements[0].members;
                        // Go through all the objects and remove all the nodes
                        // The nodes confuse the json to geojson converter
                        // Necessary so that the correct feature is returned later
                        var filtered = members.filter(function (value) {
                            return value.type != "node";
                        });
                        json.elements[0].members = filtered;
                    }
                    addGeoJson(json, point, options);
                } else {
                    // If OP failed check photon to see if there happens to be a road here
                    checkRoad(point);
                }
            });
            // TODO add .catch
        }

        // Converts json to geoJson
        // Adds data to the layer on the map
        function addGeoJson(json, point, options) {
            if (json.elements.length >= 1) {
                // Remove any old polygons drawn on the map
                state.layerGroup.removeLayer(state.geojsonLayer);
                // Convert the json to geoJson
                var gj = state.osmtogeojson(json, options);
                console.log("GeoJson", gj)
                var feature = gj.features[gj.features.length - 1];
                gj.features = [];
                gj.features.push(feature);
                state.geojsonLayer = L.geoJSON(gj);
                state.layerGroup.addLayer(state.geojsonLayer);
                if (gj.features.length != 0) {
                    state.selection.marker = point;
                    var arr = [];
                    gj.features.forEach((element) => {
                        arr.push(element.properties);
                    });
                    state.selection.info = arr;
                    state.selection.geojson = gj;
                    state.selection.ready = true;
                }
            } else {
                console.log("Nothing from overpass");
                state.selection.marker = point;
                state.selection.geojson = mapfunctions.emptyGeoJson();
                state.selection.ready = true;
            }
        }

        // Checks if a road is clicked
        // Reverse geocode using photon
        // Photon gets osmID then query overpass using the ID for geometry
        function checkRoad(point) {
            mapQuery
                .reverseGeoPhoton(point.pointX, point.pointY)
                .then((json) => {
                    // Options for converting json to geoJson (ignore nodes with a name)
                    // If these aren't set it could detect a crossing rather than the road
                    let options = {
                        uninterestingTags: (tags) => {
                            let tagNames = Object.getOwnPropertyNames(tags);
                            if (tagNames.includes("name")) {
                                return false;
                            }
                            return true;
                        },
                    };
                    addGeoJson(json, point, options);
                })
                .catch((ex) => {
                    console.log(ex);
                });
        }

        // ##########  BOUNDARY CHANGES ##########

        // Triggers when the user clicks the change boundary button
        // Removes teh geojson boundary
        // Switches off OP querying on click so the user can click to update the border
        // TODO have it remove a custom boundary if already existing
        function changeBoundary() {
            state.updatingBoundary = true;
            state.layerGroup.removeLayer(state.geojsonLayer);
            state.selection.circleMarkers = [];
            state.polyline = [];
        }

        // Triggers when the user clicks the map afeter clicking change boundary
        // Draws a polyline and dots where the user has clicked
        function addToBoundary(point) {
            point = L.latLng(point.pointX, point.pointY);
            state.selection.circleMarkers.push(point);
            state.polyline.push(point);
        }

        // Triggers when the user decides to cancel their boundary change
        function cancelBoundary() {
            state.updatingBoundary = false;
            if (state.polygon.length == 0) {
                state.layerGroup.addLayer(state.geojsonLayer);
            }
            state.selection.circleMarkers = [];
            state.polyline = [];
        }

        // Triggered when update boundary button clicked
        function updateBoundary() {
            // Set the polygon and clear the objects used for drawing
            state.polygon = state.polyline;
            state.selection.circleMarkers = [];
            state.polyline = [];
            // Remove the boundary from the OP object, set its boundaries to the user described one
            state.selection.geojson.features[0].id = null;
            state.selection.geojson.features[0].geometry.coordinates[0] =
                state.polygon;
            state.updatingBoundary = false;
        }

        return {
            state,
            initialize,
            addToMap,
            changeBoundary,
            cancelBoundary,
            updateBoundary,
            loadPin,
            pinClick,
        };
    },
};
</script>

<style scoped>
#mapAdd {
    height: 100%;
}

#mapAdd {
    display: flex;
    flex-direction: row;
}

.map-wrapper {
    width: 100%;
}

.map-container {
    margin: 0;
}

.info-wrapper {
    width: 350px;
    flex-shrink: 0;
    height: 100%;
}
</style>
