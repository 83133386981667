<template>
  <div id="userAdd">
    <map-add/>
  </div>
</template>

<script>
import MapAdd from '../components/Map/MapAdd.vue'

export default {
  name: 'UserAdd',
  components: {
    MapAdd,
  }
}
</script>

<style scoped>
  #userAdd {
    height: 100%;
  }
</style>
